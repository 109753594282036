<template>
	<div class="pt-3 px-5 pb-2">
        <div class="mb-2">
            <breadCrumbs :routesArray="routesArray"></breadCrumbs>
	    </div>
		<div class="items-center justify-between flex">
			<div class="title flex items-center">
				<h1>Controls</h1>
			</div>
			<div class="flex items-center">
                <div class="flex parent-toggle-border border rounded-sm border-lightgrey bg-white">
                <!-- <button :class="{ 'active-tab': activeButton === 'myControls', 'inactive-tab': activeButton !== 'myControls' }" class="flex items-center font-semibold justify-center rounded p-px leading-3 cursor-pointer w-28 h-8 text-xs" @click="showMyControls">
                    My Controls
                </button>
                <button :class="{ 'active-tab': activeButton === 'allControls', 'inactive-tab': activeButton !== 'allControls' }" class="flex items-center font-semibold justify-center rounded p-px leading-3 cursor-pointer w-28 h-8 text-xs" @click="showAllControls">
                    All Controls
                </button> -->
            </div>
            <!-- <button class="btnprimary ml-3 hidden items-center" @click="showAddControlPopup()">
                <img src="@/assets/plusIcon.svg" class="h-3 mr-2" />
                Add
            </button>
            <button class="filterButton ml-3 flex" @click="filterOpen" :class="sortedData.length == 0 ? 'btndisabled pointer-events-none' : 'filterButton'">
                Filters
                <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
            </button> -->
             <div class="flex items-center">
                    <div class="flex parent-toggle-border border rounded-sm border-lightgrey bg-white mr-2">
                            <button :class="{ 'active-tab': activeButton === 0, 'inactive-tab': activeButton !== 0 }" class="flex items-center font-semibold justify-center rounded p-px leading-3 cursor-pointer w-28 h-8 text-xs" @click="getAllDetailsControls(0)">
                                Mandatory
                            </button>
                            <button :class="{ 'active-tab': activeButton === 1, 'inactive-tab': activeButton !== 1 }" class="flex items-center font-semibold justify-center rounded p-px leading-3 cursor-pointer w-28 h-8 text-xs" @click="getAllDetailsControls(1)">
                                All Controls
                            </button>
                    </div>
                    <div>
						<button type="button" class="btnprimary mr-3" :class="disableStatus ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="mapControlsToBeforeState()">Cancel</button>
					</div>
					<div>
						<button type="button" class="btnprimary mr-3" :class="disableStatus ? 'btndisabled pointer-events-none' : 'btnprimary'" @click="mapControls()">Save</button>
					</div>
					<button class="flex" :class="!columnObject ? 'filterButton' : 'filterButtondisabled pointer-events-none'" @click="filterOpen">
						Filters
						<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
					</button>
				</div>
			</div>
		</div>
		<div class="mt-6">
			<p class="text-xs">
				This course helps you to Understand current data protection legislation and underlying principles. It also helps you to Understand what Privacy Awareness is, what PII is, PII Processing, Individual Rights, Cross Border Transfers, PII Breaches, Privacy By Design. This course helps you
				to understand what Information Security is, awareness on information security, different kinds of security breaches happening in daily life, physical security, email security, Knowing your responsibilities when dealing with Information Security.
			</p>
		</div>
        <div class="mt-4">
			<div class="tableCornerRadius bg-white"><div class="table-container">
				<table>
					<thead>
						<th class="header cursor-pointer w-20">
                            <div class="flex items-center ml-4">
                                <input type="checkbox" class="checkbox cursor-pointer" name="checkbox" disabled />
                            </div>
                        </th>
						<!-- <th scope="col" v-for="column in columnNames" :key="column" class="bg-white th1 cursor-pointer capitalize" :class="column == 'controlNumber'? 'w-32':''|| column == 'controlName'? 'w-44':''||column!==('controlNumber'||'controlName') ? 'w-30':''"> -->
                          <th scope="col" v-for="column in columnNames" :key="column" class="bg-white th1 cursor-pointer capitalize" :class="column == 'controlNumber'? 'w-32':''|| column == 'controlName'? 'w-44':''|| column!==('controlNumber'||'controlName') ? 'Width30':''">
							<div class="flex">
							    <!-- <input type="text" class="inputboxstyling" placeholder="Search" v-model="filters[name]" /> -->

                                <div v-if="column == 'controlNumber'">
                                    <div>{{ 'Control' }}</div>
                                </div>
                                <div v-else-if="column == 'controlName'">
                                    <div>{{ 'Title' }}</div>
                                </div>
                                 <div v-else-if="column == 'team'">
                                    <div>{{ 'Team' }}</div>
                                </div> <div v-else-if="column == 'entity'">
                                    <div>{{ 'Entity' }}</div>
                                </div>
                                <div v-else class="cursor-pointer">{{ column }}</div>
								<div @click="sort(column)" v-if="!isColumnFrameWork(column)" class="sort cursor-pointer" :class="className == column ? classSort : 'opacity-50'"></div>
							</div>
						</th>
						<tr v-if="showFilters">
                            <td></td>
							<td v-for="name in columnNames" class="bg-white" :key="name">
                                <div v-if="isColumnFrameWork(name)"></div>
								<input v-else-if="name == 'controlName'" type="text" class="searchForInputBoxStyling" placeholder="Search" v-model="filterDummy[name]" />
								<input v-else type="text" class="inputboxstylingForControlID" placeholder="Search" v-model="filterDummy[name]" />
							</td>
						</tr>
					</thead>
                    <!-- <tbody v-if="sortedData.length == 0">
                        <tr class="cursor-pointer rowHeight">
                            <td class="relative h-10" :colspan="columnNames.size">
                                <div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
                            </td>
                        </tr>
                    </tbody> -->
                    <tbody v-if="dataLoading == true">
                    <tr class="cursor-pointer empty rowHeight">
                        <td class="relative h-5" :colspan="columnNames.size">
                            <div class="flex items-center justify-center w-full h-16 skeleton">
                               <dots-loader />
                            </div>
                        </td>
                    </tr>
                </tbody>
                    <div v-else-if="columnObject || sortedData.length === 0" class="flex items-center justify-center centerNoData">No Records Found</div>
					<tbody v-else class="tbodylast">
						<tr v-for="(row, index) in sortedData" :key="index" class="rowHeight">
							<td>
								<div class="flex items-center ml-4">
									<input type="checkbox" name="checkbox" class="checkbox cursor-pointer" v-bind:value="row._id" v-model="selectedObjects" :checked="checkCheckbox()"/>
								</div>
							</td>
                            <!-- <td v-if="activeButton == 1">
								<div class="flex items-center ml-4">
									<input type="checkbox" name="checkbox" class="checkbox cursor-pointer" v-bind:value="row._id" v-model="selectedObjects" :checked="checkCheckbox()"/>
								</div>
							</td> -->
                            <td v-for="presentColumn in columnNames" :key="presentColumn" @mouseout="doFalse()" class="cursor-pointer relative">
                                <div class="h-full w-full flex items-center" v-if="isColumnFrameWork(presentColumn)">
                                  
                                    <div class="h-full w-full flex flex-wrap items-center" >
                                        <div v-for="ctrlNumber in row[presentColumn]" :key="ctrlNumber"  @click="clickedOnFrameWorkControlNumber(ctrlNumber,presentColumn)" :class="getBorder()" class="text-xs px-2 py-0.5 my-1 ml-1 font-opensans bg-white font-bold h-auto flex items-center">
                                            {{ctrlNumber}}
                                        </div>
                                    </div>
                                    <!-- <div class="emptyRowAdjustment" v-else>
                                        {{'-'}}
                                    </div> -->
                                </div>
                                <!-- <div v-else-if="presentColumn == 'team'"><p class="cursor-pointer text-xs">{{ getTeamName(row[presentColumn]) }}</p></div> -->
							    <!-- <div v-else-if="presentColumn == 'entity'"><p class="cursor-pointer text-xs">{{ getLocationName(row[presentColumn]) }}</p></div> -->
                                <div v-else>
                                    <div class="table-ellipsis">
                                        <div @mousemove="toolTip(presentColumn, $event, index, row, 'text')">
                                        {{ row[presentColumn] }}
                                        </div>
                                        <div v-if="pop == true && sortedData[tipIndex][presentColumn] == tipText && tipColumn == presentColumn && tipIndex == index">
                                            <tool-tip :columnName="presentColumn" :tipText="tipText" :styling="styling"></tool-tip>
                                        </div>
                                    </div>
                                </div>
                            </td>
						</tr>
					</tbody>
				</table>
				<pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedData"></pagination>
			</div></div>
		</div>

        <popup v-if="addControlPopup" :popUp="addControlPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>New Control</h1>

                    <button class="float-right text-3xl" @click="onCloseAddPopup()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>

            <div class="popup-business">
                <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer">
                            <div class="formcontainer flex flex-col">
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-1/2 px-2">
                                        <label for="number" class="controllabel">Control Number</label><br />
                                        <input class="inputboxstyling" placeholder="Control Number" :value="controlAddObject.controlNumber" />
                                    </div>
                                    <div class="w-1/2 px-2">
                                        <label for="number" class="controllabel">Title</label><br />
                                        <input class="inputboxstyling" placeholder="Title" :value="controlAddObject.controlTitle" />
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-1/2 px-2">
                                        <label for="number" class="controllabel">Team</label><br />
                                        <select
                                            class="inputboxstyling"
                                            v-model="controlAddObject.team"
                                        >
                                            <option value='' selected disabled hidden>Please select one</option>

                                            <option v-for="option in teamObject" :key="option" :value="option.value">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="w-1/2 px-2">
                                        <label for="number" class="controllabel">Location</label><br />
                                        <select
                                            class="inputboxstyling"
                                            v-model="controlAddObject.entity"
                                        >
                                            <option value='' selected disabled hidden>Please select one</option>

                                            <option v-for="option in locationObject" :key="option" :value="option.value">
                                                {{ option.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </popup>
        <popup v-if="mapPopup" :popUp="mapPopup">
				<template v-slot:header>
					<div class="flex items-center justify-between py-4 px-2 rounded">
						<h1 class="pl-2">Active Controls</h1>
						<button
							class="float-right text-3xl"
							@click="
								onCancelbtn('mapPopup');
								this.mapPopup = false;
							"
						>
							<img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
						</button>
					</div>
				</template>
				<fieldset>
					<div class="flex flex-col" style="gap: 30px;">
						<div>
							<div class="statuscontainer p-2">
								<div class="formcontainer flex flex-col">
									<div class="row flex flex-row w-full" v-if="selectedControlsForMap.length > 0">
                                     
										<div class="mt-2 p-2 w-full">
											<label for="Controls" class="controllabel">Selected Controls</label>
											<!-- <textarea class="inputboxstyling h-32" disabled placeholder="Controls" v-model="controlMapping.controls" /> -->
										<div class="mt-2">
                                                <!-- <Multiselect
                                                    v-model="selectedControlsForMap"
                                                    :options="mappedControlOptions"
                                                    :multiple="true"
                                                    :disabled="true"
                                                    :close-on-select="false"
                                                    :clear-on-select="false"
                                                    open-direction="bottom"
                                                    label="label"
                                                    track-by="value"  
                                                    :hide-selected="true" 
                                                    :searchable="false"
                                                    :hideArrow="false"
                                                    class="custom-multiselect"    
                                               >
                                               </Multiselect> -->
                                               <!-- {{mappedControlOptions}} -->
                                               <div class="h-32 mt-2 overflow-y-auto border border-lightgrey p-2 rounded-sm">
												<div class="text-sm flex flex-wrap">
													<div v-for="map in mappedControlOptions" :key="map" class="pb-2">
														<div class="bg-primary mr-3 px-2 rounded-sm flex items-center text-white text-sm table-ellipsis">  
                                                            <div>{{map.label}} </div>
                                                        </div>
													</div>
												</div>
											</div>
                                            </div>
										</div>
									</div>
									<div class="row flex flex-row w-full" v-if="deSelectControlsForMap.length > 0">
										<div class="p-2 w-full">
											<label for="Controls" class="controllabel mb-2">Deselected Controls</label>
											<!-- <textarea class="inputboxstyling h-32" disabled placeholder="Controls" v-model="controlMapping.controls" /> -->
                                            <div class="mt-2">
                                            <!-- <Multiselect
                                                v-model="deSelectControlsForMap"
                                                :options="deSelectedControlOptions"
                                                :multiple="true"
                                                :disabled="true"
                                                :close-on-select="false"
                                                :clear-on-select="false"
                                                open-direction="bottom"
                                                label="label"
                                                track-by="value"  
                                                :hide-selected="true" 
                                                :searchable="false"
                                                :hideArrow="false"
                                                class="custom-multiselect"    
                                            >
                                            </Multiselect> -->
                                             <div class="h-32 mt-2 overflow-y-auto border border-lightgrey p-2 rounded-sm">
												<div class="text-sm flex flex-wrap">
													<div v-for="map in deSelectedControlOptions" :key="map" class="pb-2">
														<div class="bg-primary mr-3 px-2 rounded-sm flex items-center text-white text-sm table-ellipsis"> 
                                                            <div> {{map.label}} </div>
                                                        </div>
													</div>
												</div>
											</div>
                                            </div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="buttonposition flex justify-center items-center mb-4 mx-0">
							<!-- <button class="btnprimary opacity-100" @click="onCancelbtn()">Cancel</button> -->
							<button v-if="isLoading && deSelectControlsForMap.length > 0" class="btnprimary opacity-100"  :disabled ="clickOnButton" @click="saveControls()">Update</button>
							<button v-else class="btnprimary opacity-100"  :disabled ="clickOnButton" @click="saveControls()">Save</button>
						</div>
					</div>
				</fieldset>
			</popup>

        <popup v-if="viewControlNumberPopup" :popUp="viewControlNumberPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>{{frameWorkControlNumberObject.selectedFrameWork}}</h1>

                    <button class="float-right text-3xl" @click="onCloseFrameWorkControl()">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>

            <div class="popup-business">
                <div>
                    <div>
                        <div class="statuscontainer popupbodycontainer">
                            <div class="formcontainer flex flex-col">
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label for="number" class="controllabel">Frame Work No:</label><br />
                                        <div class="text-sm mt-2">{{frameWorkControlNumberObject.controlNumber}}</div>
                                        <!-- <input class="inputboxstyling" placeholder="Frame Work Number" :value="frameWorkControlNumberObject.controlNumber" /> -->
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label for="number" class="controllabel">Title</label><br />
                                        <div class="text-sm mt-2">Access Check</div>
                                        <!-- <div class="text-sm">{{frameWorkControlNumberObject.controlTitle}}</div> -->
                                        <!-- <input class="inputboxstyling" placeholder="Title" :value="frameWorkControlNumberObject.controlTitle" /> -->
                                    </div>
                                </div>
                                <div class="row flex flex-row w-full upperspacing">
                                    <div class="w-full">
                                        <label for="number" class="controllabel">Description</label><br />
                                        <div class="text-sm mt-2">Access Enforcement</div>
                                        <!-- <div class="text-sm">{{frameWorkControlNumberObject.description}}</div> -->
                                        <!-- <textarea
                                            class="inputboxstyling"
                                            placeholder="Threat"
                                            v-model="frameWorkControlNumberObject.description"
                                        /> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </popup>
	</div>
</template>
<style scoped>
.Width30{
    width:120px;
}
.popup-business {
    overflow-y: scroll;
}
::-webkit-scrollbar {
    width: 0px;
}
tr:last-child {
	border-bottom: 1px solid #e9e9e9;
}
.rowHeight {
	height: 100px;
}
.centerNoData {
    font-size: 12px;
    height: 60px;
    padding: 0px 20px;
    font-family: 'Open Sans';
    color: black;
    /* border-top: 1px solid #e9e9e9; */
}
.table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.numberBorder{
    border-top: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
}
.numberNavy{
    border-left: 1px solid #152A43;
    border-left-width: 2px;
}
.active-tab {
    background-color: #152a43;
    color: white;
}
.active-tab, .inactive-tab {
    transition: background-color 0.4s ease, color 0.4s ease;
}
.inactive-tab {
    color: black;
    fill: #152a43 !important;
}
.emptyRowAdjustment{
    padding-left : 47%;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import _ from 'lodash';
import { required } from '@vuelidate/validators';
import tableData from '@/components/tableData.vue';
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import { emitter, toast } from '@/main';
import { groupBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import pagination from '@/components/pagination.vue';
import popup from '@/components/popup.vue';
import toolTip from '@/components/toolTip.vue';
import breadCrumbs from '@/components/breadCrumb.vue'
import DotsLoader from '@/components/dotsLoader.vue';

export default defineComponent({
	data(): any {
		return {
            controlMapping: {
				controls: []
			},
			showFilters: false,
            isLoading:false,
            mapPopup: false,
            allFrameWorkControls:[],
            orgFrameWorks:[],
            tenantFrameWorks: [],
            presentComplianceFrameWorks:[],
            tableRow:[],
            mainHeaders:[],
            filterDummy: {},
			classSort: '',
			className: '',
			dummyList:[],
			selectedObjects:[],
            mandatoryControlsList: [],
            detailsControlsList: [],
            mappedControlOptions : [],
			selectedControlsForMap : [],
			deSelectControlsForMap : [],
			deSelectedControlOptions : [],
            dummyDeleteControls: [],
            columnObject: false,
			pageType: '',

			currentSort: 'name',
			currentSortDir: 'asc',

			currentPage: '',
			pageSize:'',
			dynamicPagination: '',
            teamObject:[],
            locationObject:[],
            pop: false,
			styling: { left: '', bottom: '' },
			tipText: '',
			tipColumn: '',
			tipIndex: '',
            activeButton:'',
            addControlPopup:false,
            controlAddObject:{
                controlNumber:'',
                controlTitle:'',
                team:'',
                entity:'',
            },
            teamsData:[],
            viewControlNumberPopup:false,
            frameWorkControlNumberObject:{},
            routesArray: [],
            presentAudit:{},
            internalControlsDetailsArray:[],
            mainAuditControls:[],
            dummyObject: [],
            controlRemainObject: [],
            clickOnButton:false,
            dataLoading:true,

		};
	},
	components: {
        pagination,
        popup,
        toolTip,
        breadCrumbs,
        Multiselect,
        DotsLoader
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
        sortedData: function (): any {
			return [...this.tableRow]
				.filter((row: any, index: any) => {
					for (const key in this.filterDummy) {
						const inputValue = this.filterDummy[key];
						if (inputValue !== '' && row[key]) {
							if (Number.isInteger(row[key])) {
								if (row[key] !== Number(inputValue)) {
									return false;
								}
							} else {
								if (!row[key].toLowerCase().includes(inputValue.toLowerCase())) {
									return false;
								}
							}
						}
					}
					return true;
				})
				.sort((a, b) => {
					let modifier = 1;
					if (this.currentSortDir === 'desc') modifier = -1;
					if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					return 0;
				})
				.filter((row, index) => {
					this.dynamicPagination = index + 1;
					let start = (this.currentPage - 1) * this.pageSize;
					let end = this.currentPage * this.pageSize;
					if (index >= start && index < end) return true;
				});
		},
        columnNames: function (): any {
			const names = new Set();
			for (const key of this.mainHeaders) {
				names.add(key);
			}
			return names;
		},
        disableStatus: function (): boolean {
			const arraysEqual = (arr1: any[], arr2: any[]): boolean => {
				if (arr1.length !== arr2.length) return false;
				return arr1.every((value, index) => arr2.includes(value));
			};
			return arraysEqual(this.selectedObjects, this.dummyDeleteControls);
		}
	},
    async created(){
        this.dataLoading=true;
        await this.getTenantFrameWorks()
    },
	async mounted() {
        await this.getAllDetailsControls(1)
        await this.getAllTeams();
        this.dataLoading = false;
        await this.getAllDetailsControls(0)
        
        
        let teamName = this.teamObject.find((team: any)=>{return team.value == this.$route.params.id;})
        this.routesArray = [
            { name: "Teams", routeName: "controlMapping" },
            { name: teamName.label, routeName: "" },
        ];
	},
	methods: {
        checkCheckbox() {
			let defaultInfoArr: Array<any> = [];
			this.dummyObject.map((obj: any) => {
				defaultInfoArr.push(obj);
			});

			let finalInfoArr: Array<any> = [];
			this.selectedObjects.map((obj: any) => {
				finalInfoArr.push(obj);
			});
			if (JSON.stringify(defaultInfoArr) !== JSON.stringify(finalInfoArr)) {
				this.defNotEqual = true;
			} else {
				this.defNotEqual = false;
			}
		},
        disabledCheckedControls(id: any){
            return this.selectedObjects.includes(id)
            // console.log("this.selectedObjects", this.selectedObjects)
            // this.selectedObjects.map((obj: any) => {
            //     // console.log("obj", obj)
            //     if(obj == id){
            //         return true;
            //     } else {
            //         return false;
            //     }
            // });
        },
        async saveControls() {
			if (this.deSelectControlsForMap.length > 0) {
                this.clickOnButton = true
				let payload: any = [];
				let controlRouteId: any[] = [];
                console.log("deSelectControlsForMap", this.deSelectControlsForMap)
				for (let i = 0; i < this.deSelectControlsForMap.length; i++) {
					let deleteObj = this.deSelectControlsForMap[i];
					// let foundArr = this.dummyDeleteControls.find((obj: any) => obj._id === deleteObj._id);
					// if (!foundArr) {
						controlRouteId.push(deleteObj.value);
					// }
				}
				let departmentRouteId = this.$route.params.id;
				let entityRouteId = this.$route.params.entityId;
				// payload = [
				// 	{
				// 		departmentId: departmentRouteId,
                //         entityId: entityRouteId,
				// 		controlIds: controlRouteId,
                //         type: 20303
				// 	}
				// ];
                if(entityRouteId == 'Global' || entityRouteId == 'global'){
                    payload = [
                        {
                            departmentId: departmentRouteId,
                            entityId: entityRouteId,
                            controlIds: controlRouteId,
                            entitytypecodeid: 20301
                        }
                    ];
                } else {
                    payload = [
                        {
                            departmentId: departmentRouteId,
                            entityId: entityRouteId,
                            controlIds: controlRouteId,
                            entitytypecodeid: 20303
                        }
                    ];
                }
                console.log("payload", payload)
                try{
				await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/manageDepartments?deselect=true`, payload).then(() => {
					this.showLoader = true;
					this.mapPopup = false;
				});
				toast.info('Updated Successfully', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}catch(e){
                 toast.error(`cannot updated`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                    });
            }
            finally{
                this.clickOnButton = false
            }
            }
			if (this.selectedControlsForMap.length > 0) {
                this.clickOnButton = true;
				let payload: any = [];
				let controlRouteId: any[] = [];

				for (let i = 0; i < this.selectedObjects.length; i++) {
					let selectedObj = this.selectedObjects[i];
					let foundArr = this.dummyDeleteControls.find((obj: any) => obj === selectedObj);
					if (!foundArr) {
						controlRouteId.push(selectedObj);
					}
				}
				let departmentRouteId = this.$route.params.id;
                let entityRouteId = this.$route.params.entityId;
                if(entityRouteId == 'Global' || entityRouteId == 'global'){
                    payload = [
                        {
                            departmentId: departmentRouteId,
                            entityId: entityRouteId,
                            controlIds: controlRouteId,
                            entitytypecodeid: 20301
                        }
                    ];
                } else {
                    payload = [
                        {
                            departmentId: departmentRouteId,
                            entityId: entityRouteId,
                            controlIds: controlRouteId,
                            entitytypecodeid: 20303
                        }
                    ];
                }
                try{
				await this.$http.post(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/manageDepartments`, payload).then(() => {
					this.showLoader = true;
					this.mapPopup = false;
				});
				if (this.deSelectControlsForMap.length == 0) {
					toast.info('Saved Successfully', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
                }catch(e){
                     toast.error(`cannot Save`, {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                    });
                }finally{
                    this.clickOnButton = false;
                }
			}
			this.dummyDeleteControls = this.selectedObjects;
			this.selectedControlsForMap = [];
			this.deSelectControlsForMap = [];
			// await this.fetchActiveControls();
			await this.fetchControlsInfo();
			this.showLoader = false;
        },
        mapControlsToBeforeState(){
            this.selectedObjects = this.dummyDeleteControls
        },
        mapControls() {
			this.mappedControlOptions = [];
			this.selectedControlsForMap = [];
			this.deSelectControlsForMap = [];
			this.deSelectedControlOptions = [];
			this.mapPopup = true;
            
            for (let i = 0; i < this.selectedObjects.length; i++) {
                let selectedObj = this.selectedObjects[i];
                let foundArr = this.dummyDeleteControls.find((obj: any) => obj === selectedObj);
				if (!foundArr) {
                    let allControlslist = [...this.detailsControlsList]
                    if(allControlslist && allControlslist.length != 0){
                        allControlslist.map((controlObj: any)=>{
                            if(controlObj._id == selectedObj){
                                this.selectedControlsForMap.push({ value: controlObj._id, label: controlObj['controlNumber'] });
                                this.mappedControlOptions.push({ value: controlObj._id, label: controlObj['controlNumber'] });
                            }
                        })
                    } else {
                        this.internalControlsDetailsArray.map((controlObj: any)=>{
                            if(controlObj._id == selectedObj){
                                this.selectedControlsForMap.push({ value: controlObj._id, label: controlObj['controlNumber'] });
                                this.mappedControlOptions.push({ value: controlObj._id, label: controlObj['controlNumber'] });
                            }
                        })
                    }
                }
            }
            let uniqueIds = new Set(this.selectedObjects.map((obj: any) => obj));
			let selectdDeleteObjects = this.dummyDeleteControls.filter((selectedId: any) => !uniqueIds.has(selectedId));
            if(this.dummyDeleteControls){
                let allControlslist:any = [...this.detailsControlsList];

                let ids:any = allControlslist.map((obj:any)=>{return obj._id});

                this.mandatoryControlsList.map((obj:any)=>{
                    if(!ids.includes(obj._id)){
                        allControlslist.push(obj)
                    }
                })

                    if(allControlslist && allControlslist.length != 0){
                        allControlslist.map((controlObj: any)=>{
                            if(selectdDeleteObjects.includes(controlObj._id)){
                                this.deSelectControlsForMap.push({ value: controlObj._id, label: controlObj['controlNumber'] });
                                this.deSelectedControlOptions.push({ value: controlObj._id, label: controlObj['controlNumber'] });
                            }
                        })
                    } else {
                        this.internalControlsDetailsArray.map((controlObj: any)=>{
                            if(selectdDeleteObjects.includes(controlObj._id)){
                                this.deSelectControlsForMap.push({ value: controlObj._id, label: controlObj['controlNumber'] });
                                this.deSelectedControlOptions.push({ value: controlObj._id, label: controlObj['controlNumber'] });
                            }
                        })
                    }
                // this.internalControlsDetailsArray.map((controlObj: any)=>{
                //     if(selectdDeleteObjects.includes(controlObj._id)){
                //         this.deSelectControlsForMap.push({ value: controlObj._id, label: controlObj['controlNumber'] });
                //         this.deSelectedControlOptions.push({ value: controlObj._id, label: controlObj['controlNumber'] });
                //     }
                // })
            }
		},
        isColumnFrameWork(presentColumn:any){
            if(this.presentComplianceFrameWorks.includes(presentColumn)){
                return true
            }else{
                return false
            }
        },
        onCancelbtn(action: any) {
			// this.v$.$reset();
			if (action == 'popUpControl') {
				Object.keys(this.controlObjectiveObj).forEach((prop) => {
					Array.isArray(this.controlObjectiveObj[prop]) ? (this.controlObjectiveObj[prop] = []) : (this.controlObjectiveObj[prop] = '');
				});
			} else if (action == 'mapPopup') {
				Object.keys(this.controlMapping).forEach((prop) => {
					Array.isArray(this.controlMapping[prop]) ? (this.controlMapping[prop] = []) : (this.controlMapping[prop] = '');
				});
			}
		},
        clickedOnFrameWorkControlNumber(ctrlNum:any,frameWork:any){
            let findObj:any = this.allFrameWorkControls.find((obj:any)=>{return obj.controlNumber == ctrlNum && obj.controlFrameWork ==  frameWork})
            this.frameWorkControlNumberObject.selectedFrameWork = frameWork;
            this.frameWorkControlNumberObject.controlNumber = ctrlNum;
            if(findObj!= undefined){
                this.frameWorkControlNumberObject.controlTitle = findObj.controlTitle;
                this.frameWorkControlNumberObject.description = findObj.controlAuditMethodology
            }
            this.viewControlNumberPopup = true;
        },
        onCloseFrameWorkControl(){
            this.viewControlNumberPopup = false;
        },
        showAddControlPopup(){
            this.addControlPopup = true
        },
        onCloseAddPopup(){
            this.addControlPopup = false
            Object.keys(this.controlAddObject).forEach((prop) => {
				Array.isArray(this.controlAddObject[prop]) ? (this.controlAddObject[prop] = []) : (this.controlAddObject[prop] = '');				
			});
        },
       async mandatoryControls(index: any){
            if(index == 0){
                this.activeButton = 0
            }
            else if(index == 1){
                this.activeButton = 1
            }
        },
        getBorder(){
            return `numberBorder numberNavy`
        },
        toolTip(column: any, e: any, index: any, row: any, status: any) {
			this.tipText = row[column];
			this.tipColumn = column;
			this.tipIndex = index;

			switch (status) {
				case 'text':
					this.handleTextTooltip(e);
					break;
				case 'circleButton':
					if (e.offsetX < e.target.offsetWidth && e.offsetY < e.target.offsetHeight) {
						this.pop = true;
						this.styling.left = e.offsetX + e.target.offsetLeft + 13 + 'px';
						this.styling.bottom = -15 + 'px';
					}
			}
		},
		handleTextTooltip(e: any) {
			const container = e.currentTarget.closest('.table-ellipsis');
			// console.log("tooltip",container)
            // console.log("isTextOverflowed", this.isTextOverflowed(container))
			if (container && this.isTextOverflowed(container)) {
				this.pop = true;
				this.styling.left = e.offsetX + 38 + 'px';
				this.styling.top = 45 + 'px';
			} else {
				this.pop = false;
			}
		},
		isTextOverflowed(element: any) {
            // console.log("element.scrollHeight", element.scrollHeight)
			// console.log("element.clientHeight", element.clientHeight)
			return element.scrollHeight > element.clientHeight;
		},
		doFalse() {
			this.pop = false;
		},
        sort(tableHead: any) {
			this.className = tableHead;
			if (tableHead == this.currentSort) {
				this.currentSortDir = this.currentSortDir == 'asc' ? 'desc' : 'asc';
			}
			this.currentSort = tableHead;
			this.classSort = this.currentSortDir == 'asc' ? 'sort_asc' : 'sort_desc';
		},
		paginationUpdations(currentPage: any, pageSize: any) {
			this.currentPage = currentPage;
			this.pageSize = pageSize;
		},
		filterOpen() {
			this.showFilters = !this.showFilters;
			emitter.emit('visible', this.showFilters);
            this.filterDummy = {}
		},
        getTeamName(ownerId: any) {
            let user = this.teamObject.find((obj: any) => {
                return obj.value == ownerId;
            });
            if (user == undefined) {
                let empty = '';
                return empty;
            } else {
                return user.label;
            }
        },
		getLocationName(locationId: any) {
            let locationObj = this.locationObject.find((obj: any) => {
                return obj.value == locationId;
            });
            if (locationObj == undefined) {
                let empty = '';
                return empty;
            } else {
                return locationObj.label;
            }
        },
        async fetchControlsInfo() {
            this.dummyObject = [];
            this.selectedObjects = [];
            this.showLoader = true;
            await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/controls/active`).then((result: { data: any }) => {
                this.isLoading = true;
                this.showLoader = false;
                let controlList: any[] = [];
                _.each(
                    result.data,
                    (control: { department: any; departments: string | any[] }) => {
                    if (control.departments.length > 0) {
                        _.each(control.departments, (dept: any) => {
                        control.department = dept;
                        });
                    }
                    let updatedObject = Object.assign({}, control);
                    controlList.push(updatedObject);
                    }
                );
                this.controlsList = controlList;
                this.dummyList = controlList;
                this.controlsList.forEach((control: any)=>{
                    if(this.detailsControlsList && this.detailsControlsList.length != 0){
                        this.detailsControlsList.forEach((obj: any) => {
                            if((control.refernceframeworkId === obj._id || control.linkedControlID === obj._id) && control.departments == this.$route.params.id){
                                obj.frameworks.forEach((frame: any) => {
                                    this.presentComplianceFrameWorks.push(frame.framework);
                                });

                                let sendObject: any = {
                                    _id: obj._id,
                                    controlNumber: obj.controlNumber,
                                    controlName: obj.controlTitle,
                                    complianceStatus: 10401
                                };

                                obj.frameworks.forEach((frame: any) => {
                                    if (this.presentComplianceFrameWorks.includes(frame.framework)) {
                                        sendObject[frame.framework] = frame.controls;
                                    }
                                });
                                this.selectedObjects.push(sendObject._id);
                                this.dummyObject = this.selectedObjects;
                                this.dummyDeleteControls = [...this.selectedObjects];
                            }
                        });
                    } else {
                        this.internalControlsDetailsArray.forEach((obj: any) => {
                            if((control.refernceframeworkId === obj._id || control.linkedControlID === obj._id) && control.departments == this.$route.params.id){
                                obj.frameworks.forEach((frame: any) => {
                                    this.presentComplianceFrameWorks.push(frame.framework);
                                });

                                let sendObject: any = {
                                    _id: obj._id,
                                    controlNumber: obj.controlNumber,
                                    controlName: obj.controlTitle,
                                    complianceStatus: 10401
                                };

                                obj.frameworks.forEach((frame: any) => {
                                    if (this.presentComplianceFrameWorks.includes(frame.framework)) {
                                        sendObject[frame.framework] = frame.controls;
                                    }
                                });
                                this.selectedObjects.push(sendObject._id);
                                this.dummyObject = this.selectedObjects;
                                this.dummyDeleteControls = [...this.selectedObjects];
                            }
                        });
                    }
                })
            })
            .catch((error: any) => {
                this.isLoading = false;
                (this.showLoader = false),
                    this.$toasted.error(error, {
                    theme: "toasted-primary",
                    duration: 1000,
                    });
            });
        },
        async getAllControls(){
            await this.$http
				.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls`)
				.then((result: { data: any }) => {
                    this.allFrameWorkControls = result.data;
				})
				.catch((error: any) => {
                    toast.error(` ${error}`, {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
				});
        },
        async getAllTeams() {
            this.teamObject = []
            let users:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
//                 res.data = [
//     {
//         "_id": "66fbe72919c32f06aaaa5276",
//         "teamName": "Development Team",
//         "displayName": "Development Team 1",
//         "entityType": 20303,
//         "entityName": "My Entity 1",
//         "entityDisplayName": "MyEntity1",
//         "entityOwner": "6226dcaaf974bdd1169d5d85"
//     },
//     {
//         "_id": "66fbe72919c32f06aaaa5277",
//         "teamName": "Development Team",
//         "displayName": "Development Team",
//         "entityType": 20303,
//         "entityName": "My Entity 2",
//         "entityDisplayName": "MyEntity2",
//         "entityOwner": "6226dcaaf974bdd1169d5d85"
//     },
//     {
//         "_id": "66fbe84319c32f06aaaa5278",
//         "teamName": "Global UI Team",
//         "displayName": "Team Global UI3",
//         "entityType": 20301,
//         "globalHead": "66c6bec260fe55695a7212a2",
//         "type": 20301
//     },
//     {
//         "_id": "66fbea9819c32f06aaaa5279",
//         "teamName": "Information Tech",
//         "displayName": "IT",
//         "entityType": 20303,
//         "entityName": "Mannelabs Hyd",
//         "entityDisplayName": "Mannelabs Hyd",
//         "entityOwner": "6226dcaaf974bdd1169d5d85"
//     },
//     {
//         "_id": "66fbea9819c32f06aaaa527a",
//         "teamName": "Information Tech",
//         "displayName": "IT",
//         "entityType": 20303,
//         "entityName": "Mannelabs-BNGLR",
//         "entityDisplayName": "Mannelabs-BNGLR",
//         "entityOwner": "66de792260fe55695a72134c"
//     },
//     {
//         "_id": "66fbf97419c32f06aaaa527b",
//         "teamName": "Global Team",
//         "displayName": "Global Ux",
//         "entityType": 20301,
//         "globalHead": "66c6bec260fe55695a7212a2"
//     }
// ];
                users = res.data;
                this.teamsData = res.data;
            });
            for (var i = 0; i < users.length; i++) {
                this.teamObject.push({ label: users[i].teamName, value: users[i]._id });
            }
        },
        // async fetchControlsInfo() {
		// 	try {
		// 		(this.showLoader = true), (this.isLoading = false);
		// 		await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/allControls`).then((result: { data: any }) => {
		// 			this.isLoading = true;
		// 			this.showLoader = false;
		// 			this.selectedObjects = [];
		// 			this.dummyList = result.data;
		// 			this.controlRemainObject = [];
		// 			let activeControls: any[] = [];
		// 			result.data.forEach((control: any) => {
		// 				activeControls = this.controlsActiveList.find((item: any) => {
		// 					return item.departments == this.$route.params.id && item.controlNumber === control.controlNumber;
		// 				});
		// 				for (const value of Object.values(control)) {
		// 					if (value == '') {
		// 						this.columnObject = true;
		// 					} else {
		// 						this.columnObject = false;
		// 					}
		// 				}
		// 				if (activeControls) {
		// 					let obj = {
		// 						_id: control._id,
		// 						title: control.controlTitle,
		// 						number: control.controlNumber,
		// 						family: control.controlFamily,
		// 						class: control.controlClass,
		// 						priority: control.controlPriority
		// 					};
		// 					this.selectedObjects.push(obj);
		// 					this.dummyObject = this.selectedObjects;
		// 					this.dummyDeleteControls = [...this.selectedObjects];
		// 				} else {
		// 					let obj = {
		// 						_id: control._id,
		// 						title: control.controlTitle,
		// 						number: control.controlNumber,
		// 						family: control.controlFamily,
		// 						class: control.controlClass,
		// 						priority: control.controlPriority
		// 					};
		// 					this.controlRemainObject.push(obj);
		// 				}
		// 				this.controlsList = [...this.selectedObjects, ...this.controlRemainObject];
		// 			});
		// 		});
		// 		if (this.controlsList.length === 0) {
		// 			this.columnObject = true;
		// 			this.controlsList.push({ title: '', number: '', family: '', class: '', priority: '' });
		// 		}
		// 	} catch (e) {
		// 		this.isLoading = false;
		// 		toast.error('error', {
		// 			timeout: 1000,
		// 			closeOnClick: true,
		// 			closeButton: 'button',
		// 			icon: true
		// 		});
		// 	}
		// },
		 async getallEntities() {
            this.locationObject = []
			let result:any
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                result = res.data;
            });
            for (var i = 0; i < result.length; i++) {
                this.locationObject.push({ label: result[i].entityName, value: result[i]._id });
            }
        },
        async getAllDetailsControls(index: any){
            await this.fetchControlsInfo()
            this.internalControlsDetailsArray = [];
            if(index == 0){
                try {
                    this.activeButton = 0
                    this.showLoader = true;
                    await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/${1}/controls/getall`).then((result: { data: any }) => {
                        this.internalControlsDetailsArray = result.data;
                        this.mandatoryControlsList = result.data
                    });
                    await this.startSelectedCompliance();
                    // await this.fetchControlsInfo()
                } catch (e) {
                    toast.error('error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }
            } else if(index == 1){
                try {
                    this.showLoader = true;
                    this.activeButton = 1
                    await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/${2}/controls/getall`).then((result: { data: any }) => {
                        this.internalControlsDetailsArray = result.data;
                        this.detailsControlsList = result.data
                    });
                    await this.startSelectedCompliance();
                    // await this.fetchControlsInfo()
                } catch (e) {
                    toast.error('error', {
                        timeout: 1000,
                        closeOnClick: true,
                        closeButton: 'button',
                        icon: true
                    });
                }
            }
            this.showFilters = false;
            this.filterDummy = {}
        },
        // async startSelectedCompliance(){
        //     this.tableRow = [];
            
        //     this.internalControlsDetailsArray.forEach((obj: any) => {
        //         obj.frameworks.forEach((frame: any) => {
        //             this.presentComplianceFrameWorks.push(frame.framework);
        //         });
        //         let sendObject: any = {
        //             _id: obj._id,
        //             controlNumber: obj.controlNumber,
        //             controlName: obj.controlTitle,
        //             complianceStatus: 10401
        //         };
                
        //         this.presentComplianceFrameWorks.forEach((key: any) => {
        //             let keys
        //             obj.frameworks.forEach((frame: any) => {
        //                 if(frame.framework == key){
        //                     keys = frame.controls;
        //                     sendObject[key] = keys;
        //                 }
        //             });
        //         });
        //         this.tableRow.push(sendObject);
        //     });

		// }
        // async startSelectedCompliance() {
        //     this.tableRow = [];
        //     this.presentComplianceFrameWorks = [];
        //     this.internalControlsDetailsArray.forEach((obj: any) => {
        //         obj.frameworks.forEach((frame: any) => {
        //             this.presentComplianceFrameWorks.push(frame.framework);
        //         });
        //         let sendObject: any = {
        //             _id: obj._id,
        //             controlNumber: obj.controlNumber != undefined ? obj.controlNumber : 'N/A',
        //             controlName: obj.controlTitle != undefined ? obj.controlTitle : 'N/A',
        //             complianceStatus: 10401
        //         };

        //         obj.frameworks.forEach((frame: any) => {
        //             if (this.presentComplianceFrameWorks.includes(frame.framework)) {
        //                 sendObject[frame.framework] = frame.controls;
        //             }
        //         });

        //         this.tableRow.push(sendObject);
        //     });
        //     let headersData;
        //     if(this.activeButton == 0){
        //         headersData = ['controlNumber','controlName', ...this.presentComplianceFrameWorks]
        //     } else if(this.activeButton == 1){
        //         headersData = ['controlNumber','controlName']
        //     }
        //     this.mainHeaders = headersData;
        // }
        getRandomFrameworks(count: any) {
            const indices = new Set();
            while (indices.size < count) {
                const randomIndex = Math.floor(Math.random() * this.tenantFrameWorks.length);
                indices.add(randomIndex);
            }
            return Array.from(indices).map((index: any) => this.tenantFrameWorks[index]);
        },
        async getTenantFrameWorks() {
			await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/tenant/${this.userInfo.organisationId}/controls/get`).then((res: any) => {
				res.data.map((frame: any)=>{
                    if(frame.frameWorkName != 'InternalControls'){
                        this.tenantFrameWorks.push(frame.frameWorkName)
                    }
                });
			});
		},
        async startSelectedCompliance() {
            this.tableRow = [];
            this.presentComplianceFrameWorks = [];
            let selectedControls: any = [];
            let remainingControls: any = [];
            this.internalControlsDetailsArray.forEach((obj: any) => {
                const frameworkList = obj.frameworks.map((frame: any) => frame.framework);
                this.presentComplianceFrameWorks.push(...frameworkList);
                
                if(this.selectedObjects.includes(obj._id)){
                    let sendObject: any = {
                        _id: obj._id,
                        controlNumber: obj.controlNumber != undefined ? obj.controlNumber : 'N/A',
                        controlName: obj.controlTitle != undefined ? obj.controlTitle : 'N/A',
                        complianceStatus: 10401
                    };
                    obj.frameworks.forEach((frame: any) => {
                        if (this.tenantFrameWorks.includes(frame.framework)) {
                            sendObject[frame.framework] = frame.controls;
                        }
                    });
                    selectedControls.push(sendObject)
                } else {
                    let controlObject: any = {
                        _id: obj._id,
                        controlNumber: obj.controlNumber != undefined ? obj.controlNumber : 'N/A',
                        controlName: obj.controlTitle != undefined ? obj.controlTitle : 'N/A',
                        complianceStatus: 10401
                    };
                    obj.frameworks.forEach((frame: any) => {
                        if (this.tenantFrameWorks.includes(frame.framework)) {
                            controlObject[frame.framework] = frame.controls;
                        }
                    });
                    remainingControls.push(controlObject)
                }
            });
            this.tableRow = [...selectedControls, ...remainingControls];
            if (this.tenantFrameWorks.length > 3) {
                this.selectedFrameworks = this.getRandomFrameworks(3);
            } else {
                this.selectedFrameworks = this.tenantFrameWorks; // or handle the case as needed
            }
            this.mainHeaders = this.activeButton === 0 
                ? ['controlNumber', 'controlName', ...this.selectedFrameworks] 
                : ['controlNumber', 'controlName'];
        }

    
	// async created() {
        // await this.startSelectedCompliance(this.presentAudit._id)
        // await this.fetchControlsInfo();
        

        // this.routesArray = [
		// 	{ name: 'Audit', routeName: 'audit' },
        //     { name: `${this.presentAudit.title} - Controls`, routeName: 'audit-controls' },
		// ];
        // this.showMyControls()
        // await this.getAllTeams();
        // await this.getallEntities();
        // await this.getAllControls()


       

        // await this.getDetailsForTable();
	// }
    }
});

</script>










