<template>
	<div class="pr-5 pl-5 pt-3">
		<div class="row w-full mb-3 justify-between flex" style="align-items: center">
			<h1>Mapping</h1>
			<div class="cardbody">
				<div class="flex flex-row-reverse items-center">
					<div class="flex flex-row-reverse items-center">
						<button class="flex" @click="filterOpen" :class="tableRow.length == 0 ? 'filterButtondisabled pointer-events-none' : 'filterButton'">
							Filters
							<img src="@/assets/filter.svg" class="h-3 ml-2.5" />
						</button>
					</div>
				</div>
			</div>
		</div>
		<div>
			<tableData :tableRowData="tableRow" :headersData="headers" :tableRouteArr="optionsValue" :loadData="dataLoading"></tableData>
			<div v-if="toolTipActive">
				<tool-tip :tipText="toolTipText"></tool-tip>
			</div>
		</div>
		<!-- <loader v-if="showLoader" /> -->
	</div>
</template>
<style scoped>
</style>
<script lang="ts">
import { emitter, toast } from '@/main';
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import tableData from '@/components/tableData.vue';
import toolTip from '@/components/toolTip.vue';
// import loader from '@/components/loader.vue';
export default defineComponent({
	data(): any {
		return {
			headers:['_id','Team Name','Entity Name','Team Owner','Total Number of Controls'],

			v$: useVuelidate(),
			departmentsList: [],
			tableRow: [],
			orgUsersList: [],
			optionsUserArray: [],
			controlsCountList: [],
			columnObject: false,
			showLoader: false,
			showFilters: false,
			toolTipActive : false,
			toolTipText : '',
			optionsValue: [
				{
					id: 1,
					value: 'Select',
					route: '/departmentControls',
					presentRoute: '/org/controls/controlMapping'
				}
			], 
			dataLoading:true,
		};
	},
	components: {
		tableData,
		// loader,
		toolTip
	},
	mounted() {
		for (const row of this.tableRow) {
			for (const value of Object.values(row)) {
				if (value == '') {
					this.columnObject = true;
				} else {
					this.columnObject = false;
				}
			}
		}
		emitter.off('controlMappingData')
        emitter.on("controlMappingData", (isData: any) => {
          	let comingId = isData.entityData._id;
            let showObj:any
            // if(isData.entityData.type == 20301){
                showObj = this.departmentsList.find((obj: any) => {
                    return obj._id === comingId;
                });
            // }else{
            //      showObj = this.departmentsList.find((obj: any) => {
            //         return obj._id === comingId && obj.entity == isData.entityData.entityId;
            //     });
            // }
           
            this.showEditPopUp(showObj);
        });
		emitter.off('activiteToolTip');
		emitter.on('activiteToolTip', (data: any) => {
			this.toolTipActive = true;
			this.toolTipText = data.tipText
		});
		emitter.off('deActiviteToolTip');
		emitter.on('deActiviteToolTip', (data: any) => {
			this.toolTipActive = false;
			this.toolTipText = ''
		});
	},
	methods: {
		showEditPopUp(obj: any) {
           this.$router.push({ name: 'departmentControls', params: { id:  obj._id } });
		},
		filterOpen() {
			if(this.columnObject == false){
				this.showFilters = !this.showFilters;
				emitter.emit('visible', this.showFilters);
			}
		},
		async getAllUsers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				this.orgUsersList = res.data;
			});
			for (var i = 0; i < this.orgUsersList.length; i++) {
				this.optionsUserArray.push({ label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id });
			}
		},
		async fetchControlsCountInfo() {
			this.controlsCountList = [];
			try {
				this.showLoader = true;
				await this.$http.get(`${process.env.VUE_APP_CONTROLS_API_URL}/api/org/departments/mappedcontrols/count`).then((result: { data: any }) => {
					this.controlsCountList = result.data;
				});
			} catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			this.showLoader = false;
		},
		async getSingleEntity(){
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
				this.organizationList = res.data;
			});
			// this.$store.dispatch('updateDotsLoading', false);
		},
		async fetchDepartmentsList() {
			try {
				this.showLoader = true;
				this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: { data: any }) => {
					this.departmentsList = res.data;
					console.log("departmentsList", this.departmentsList)
					if (this.departmentsList.length > 0) {
						this.departmentsList.forEach((department: any) => {
							let controlCount : any
 
                            let myEntity:any
                            if(department.entityType == 20301){
                                myEntity = 'global'
                            }else{
                                myEntity = department.entityDisplayName
                            }
							console.log("controlsCountList", this.controlsCountList)
							controlCount = this.controlsCountList.find((obj: any) => obj._id == department._id)
							console.log("controlCount", controlCount)
                            let finalObj = {
                                _id: department._id,
                                'Team Name': department.teamName ? department.teamName : 'N/A',
                                'Entity Name': department.entityType == 20303 ? department.entityDisplayName : department.displayName,
                                'Team Owner': this.optionsUserArray.find((user: any) => user.value === department.teamOwner || department.globalHead==user.value)?.label || 'N/A',
                                'Total Number of Controls': controlCount && controlCount.controlCount ? controlCount.controlCount : '0',
                                'entityId': department.entityDisplayName,
                                'type': department.entityType
                            };
							console.log("finalObj", finalObj)
                            this.tableRow.push(finalObj);
							this.dataLoading = false;
                        });
					} else {
						let finalObj = {
							_id: '',
							'Team Name': '',
							'Entity Name': '',
							'Team Owner': '',
							'Total Number of Controls': ''
						};
						this.tableRow.push(finalObj);
						this.dataLoading = false;
					}
				});
			} catch (e) {
				toast.error('error', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			this.showLoader = false;
		}
	},
	async created() {
		this.dataLoading = true;
		await this.fetchControlsCountInfo();
		await this.getAllUsers();
		await this.getSingleEntity();
		await this.fetchDepartmentsList();
	}
});
</script>

